import { IBillingPeriod } from "./BillingPeriod";

export enum EBillingPeriodState {
    CURRENT = "current",
    COLLECTION = "collection",
    CLOSED = "closed",
    NOT_DUE = "not_due",
}

export interface IBillingPeriodState {
    id: number;
    state: EBillingPeriodState;
    billing_period_id: number;
    created_at: number;
    BillingPeriod?: IBillingPeriod;
}